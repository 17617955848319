@font-face {
  font-family: 'Avenir Next Cyr Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Cyr Regular'), url('./fonts/avenir next cyr/AvenirNextCyr-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Next Cyr Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Cyr Italic'), url('./fonts/avenir next cyr/AvenirNextCyr-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Next Cyr Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Cyr Thin'), url('./fonts/avenir next cyr/AvenirNextCyr-Thin.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Next Cyr Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Cyr Thin Italic'), url('./fonts/avenir next cyr/AvenirNextCyr-ThinItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Next Cyr Light';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Cyr Light'), url('./fonts/avenir next cyr/AvenirNextCyr-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Next Cyr Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Cyr Light Italic'), url('./fonts/avenir next cyr/AvenirNextCyr-LightItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Next Cyr Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Cyr Medium'), url('./fonts/avenir next cyr/AvenirNextCyr-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Next Cyr Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Cyr Medium Italic'), url('./fonts/avenir next cyr/AvenirNextCyr-MediumItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Next Cyr Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Cyr Bold'), url('./fonts/avenir next cyr/AvenirNextCyr-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Next Cyr Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Cyr Bold Italic'), url('./fonts/avenir next cyr/AvenirNextCyr-BoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Next Cyr Heavy';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Cyr Heavy'), url('./fonts/avenir next cyr/AvenirNextCyr-Heavy.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Next Cyr Heavy Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Cyr Heavy Italic'), url('./fonts/avenir next cyr/AvenirNextCyr-HeavyItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Next Cyr Demi';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Cyr Demi'), url('./fonts/avenir next cyr/AvenirNextCyr-Demi.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Next Cyr Demi Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Cyr Demi Italic'), url('./fonts/avenir next cyr/AvenirNextCyr-DemiItalic.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Next Cyr Ultra Light';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Cyr Ultra Light'), url('./fonts/avenir next cyr/AvenirNextCyr-UltraLight.otf') format('opentype');
}

@font-face {
  font-family: 'Avenir Next Cyr Ultra Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next Cyr Ultra Light Italic'), url('./fonts/avenir next cyr/AvenirNextCyr-UltraLightIt.otf') format('opentype');
}





/* Определение переменных для светлой темы */
:root {
  --background-color: black;
  --text-color: white;
  --link-color: #ffffff;
  --modal-overlay-bg: rgba(0, 0, 0, 0.7);
  --contact-color: white;
  --contact-underline-bg: white;
  --button-bg: #007bff;
  --button-color: white;
  --font-family: 'Avenir Next Cyr Regular';
}

/* Определение переменных для темной темы */
.dark-theme {
  --background-color: black;
  --text-color: white;
  --link-color: #ffffff;
  --modal-overlay-bg: rgba(0, 0, 0, 0.7);
  --contact-color: white;
  --contact-underline-bg: white;
  --button-bg: #007bff;
  --button-color: white;
}

/* Определение переменных для светлой темы */
.light-theme {
  --background-color: white;
  --text-color: #000000;
  --link-color: #000000;
  --modal-overlay-bg: rgba(255, 255, 255, 0.7);
  --contact-color: black;
  --contact-underline-bg: black;
  --button-bg: #007bff;
  --button-color: black;
}

html {
  background-color: #000000;
}

/* Основные стили приложения */
.App {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 1s ease, color 1s ease;
  height: 100%;
  position: relative;
}


/* Стили для контейнера */
.container {
  cursor: n-resize;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: filter 0.3s ease;
  z-index: 3;
}

/* Стили для ссылок */
a {
  color: var(--link-color);
  text-decoration: none;
}

/* Стили для кнопки контактов */
.theme-button-light {
  background-color: #ffffff;
  /* Белый цвет */
  border: 1px solid var(--text-color);
  /* Черный бордер */
  box-sizing: border-box;
}


.theme-button-dark {
  background-color: #000000;
  /* Черный цвет */
  border: 1px solid var(--text-color);
  /* Белый бордер */

  box-sizing: border-box;
}

.theme-button {
  width: 7px;
  height: 7px;
  display: inline-block;
  cursor: pointer;
  margin: 0 10px;
}

.theme-button-container {
  z-index: 4;
  position: fixed;
  top: 27px;
  right: 23px;
}

.theme-button:hover {
  opacity: 0.8;
}

/* Стили для контента */
.content {

  z-index: 2;
  letter-spacing: 0.3vmin;
  font-family: var(--font-family);
  color: var(--text-color);
  text-align: center;
  position: sticky;
  transition: color 1s ease, color 1s ease;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Стили для имени */
.name {
  text-shadow: #000000 1px 0 10px;
  font-size: calc(10px + 2vmin);
  margin: 10px;
  transform: scale(1, 0.85);
  font-family: 'Avenir Next Cyr Bold';
  opacity: 0;
  animation: fadeIn 2s ease-out forwards;
}

/* Стили для контакта */
.contact {
  letter-spacing: 2px;
  cursor: pointer;
  font-family: var(--font-family);
  color: var(--contact-color);
  font-size: calc(1px + 1.2vmin);
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 4;
  padding-bottom: 1px;
  display: inline-block;
  transform: scale(1, 0.9);
  transition: color 1s ease, color 1s ease;
  text-shadow: #000000 1px 0 10px;
}

.contact::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: var(--contact-underline-bg);
  transition: width 0.6s ease;
}

.contact:hover::after {
  width: 100%;
}

/* Стили для роли */
.role {
  text-shadow: #000000 1px 0 10px;
  font-size: calc(1px + 1vmin);
  margin: 0;
  opacity: 0;
  animation: fadeIn 4s ease-out forwards;
}

/* Стили для бокового текста */
.side-text {
  z-index: 3;
  font-family: var(--font-family);
  color: var(--text-color);
  position: fixed;
  left: 30px;
  bottom: 90px;
  transform: rotate(90deg)scale(1, 0.85);
  transform-origin: left bottom;
  white-space: nowrap;
  transition: color 1s ease, color 1s ease;
  text-shadow: #000000 1px 0 10px;
}

.side-text::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: var(--contact-underline-bg);
  transition: width 0.6s ease;
}

.side-text:hover::after {
  width: 100%;
}


.side-text p {
  letter-spacing: 2px;
  font-family: var(--font-family);
  font-size: calc(1px + 1.3vmin);
  margin: 0;
}

.volume-control {
  position: relative;
  display: inline-block;
}

.close-modal {
  cursor: pointer;
}

.volume-slider-container {
  display: none;
  position: absolute;
  top: -40px;
  /* Положение ползунка */
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  padding: 1px;
  border-radius: 1px;
}

.volume-control:hover .volume-slider-container {
  display: block;
  /* Показывать ползунок при наведении */
}

.volume-slider {
  width: 10px;
}

/* Стили для модального окна */
.modal-overlay {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--modal-overlay-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  transform: scale(1);
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.modal-overlay.visible {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.modal-overlay.hidden {
  opacity: 0;
  visibility: hidden;
  transform: scale(1.05);
}

.modal-content {
  text-transform: uppercase;
  font-family: var(--font-family);
  letter-spacing: 3px;
  color: var(--text-color);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  line-height: 22px;
  font-size: calc(2px + 1.2vmin);
  transform: scale(1, 0.9);
}

.custom-link {
  display: inline;
  /* ensure inline display for paragraph text */
}

.custom-link a {
  position: relative;
  color: inherit;
  /* сохранить цвет текста */
  text-decoration: none;
  /* убрать стандартное подчеркивание */
  display: inline-block;
  vertical-align: baseline;
  /* align with text baseline */
}

.custom-link a:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  transform: scaleX(0);
  /* initially hidden */
  transform-origin: left;
  /* animation starts from the left */
  transition: transform 0.5s;
  /* animate transform */
}

.custom-link a:hover:before {
  transform: scaleX(1);
  /* reveal the underline */
}

.modal-content button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  background-color: var(--button-bg);
  color: var(--button-color);
  border-radius: 5px;
  cursor: pointer;
}

.undername {
  font-size: calc(1px + 2vmin);
  line-height: 0.1;
}



@keyframes fadeInOutUp {
  0% {
    opacity: 0;
    transform: translateY(0);
  }



  50% {
    opacity: 1;

  }


  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes slideOutUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.scroll-btn {
  letter-spacing: 2px;
  font-family: var(--font-family);
  font-size: 12px;
  position: absolute;
  bottom: 25px;
  color: var(--text-color);
  opacity: 0;
  animation: fadeInOutUp 1.2s ease-in-out infinite;
  transform: scale(1, 0.85);
  display: none;
}

.scroll-btn.show {
  display: block;
  /* Добавьте другие стили для кнопки */
}

.scroll-btn.visible {
  animation: slideInUp 0.5s forwards;
}

.scroll-btn.hidden {
  animation: slideOutUp 0.5s forwards;
}


.video-page-container {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  letter-spacing: 2px;
  font-family: var(--font-family);
  color: var(--text-color);
  transition: color 1s ease, color 1s ease;
  font-size: calc(1px + 1.2vmin);
  line-height: 25px;
  padding-top: 10px;
  text-transform: uppercase;
  width: 100%;

  z-index: 2;
}

.contact-name {
  font-size: calc(10px + 2vmin);
  font-family: 'Avenir Next Cyr Bold';
  padding: 10px;
}

.contact-margin {
  margin-top: 20px;
}

.video-section {
  margin-left: 15%;
  margin-top: 25px;
}

.video-type-title {
  margin-top: 15px;
  transform: scale(1, 0.9);
}

.custom-text-link {
  display: inline;
  /* Устанавливает ширину по длине текста */
  white-space: nowrap;
  /* Отключает перенос текста */
}

.video-link-container {
  display: block;
}

.video-link {
  text-shadow: #000000 1px 0 10px;
  display: inline-block;
  white-space: nowrap;
  transform: scale(1, 0.9);
  cursor: pointer;
  z-index: 1000;

}

.video-link-hovered {
  text-decoration: underline;
}

.video-link:last-child {
  margin-right: 0;
  /* Убирает отступ справа у последнего элемента */
}

.background-video {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: 1;
  pointer-events: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.background-video::-webkit-media-controls {
  display: none !important;
}

.background-video::-webkit-media-controls-enclosure {
  display: none !important;
}

.background-video::-webkit-media-controls-play-button {
  display: none !important;
}

.background-video::-webkit-media-controls-volume-slider {
  display: none !important;
}

.background-video::-webkit-media-controls-mute-button {
  display: none !important;
}

.background-video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}


.video-section {
  position: relative;
  z-index: 1;
  opacity: 0;
  transition: opacity 1.5s ease;
}

.video-section.visible {
  opacity: 1;
}

@keyframes floatUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes floatDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated-text span {
  display: inline-block;
  opacity: 0;
  animation: 0.5s ease forwards;
}

.animated-text span.up {
  animation-name: floatUp;
}

.animated-text span.down {
  animation-name: floatDown;
}

.video-normal {
  display: block;
}

.contact,
.side-text {
  opacity: 0;
  filter: blur(10px);
  /* Adjust the blur amount as needed */
  transition: opacity 1s ease, filter 1s ease;
}

.contact.visible,
.side-text.visible {
  opacity: 1;
  filter: blur(0);
  /* Remove the blur effect when visible */
}

.single-video-page-container {
  display: flex;
  width: 100%;

  background-color: #007bff;
  height: 100vh;


}

.video-mobile {
  display: none;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.loading-container {
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  background-color: #000000;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  /* Reduced radius */
  height: 20px;
}

.trapezoid {
  width: 0;
  height: 0;
  border-top: 3px solid white;
  /* Smaller top base of the trapezoid */
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 1px solid transparent;
  position: absolute;
  transform-origin: 50% 100%;
}

.trapezoid:nth-child(1) {
  transform: rotate(0deg) translate(0, -15px);
}

.trapezoid:nth-child(2) {
  transform: rotate(30deg) translate(0, -15px);
}

.trapezoid:nth-child(3) {
  transform: rotate(60deg) translate(0, -15px);
}

.trapezoid:nth-child(4) {
  transform: rotate(90deg) translate(0, -15px);
}

.trapezoid:nth-child(5) {
  transform: rotate(120deg) translate(0, -15px);
}

.trapezoid:nth-child(6) {
  transform: rotate(150deg) translate(0, -15px);
}

.trapezoid:nth-child(7) {
  transform: rotate(180deg) translate(0, -15px);
}

.trapezoid:nth-child(8) {
  transform: rotate(210deg) translate(0, -15px);
}

.trapezoid:nth-child(9) {
  transform: rotate(240deg) translate(0, -15px);
}

.trapezoid:nth-child(10) {
  transform: rotate(270deg) translate(0, -15px);
}

.trapezoid:nth-child(11) {
  transform: rotate(300deg) translate(0, -15px);
}

.trapezoid:nth-child(12) {
  transform: rotate(330deg) translate(0, -15px);
}

.loading-spinner {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

Expla .video-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-page {
  position: sticky;
  top: 0;
  left: 0;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  padding: 10px 20px;
  z-index: 1;
  overflow: hidden;
  transition: visibility 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.video-details {
  width: 70%;
  text-align: start;
  font-size: calc(1px + 1.2vmin);
  padding: 0px;
  text-transform: uppercase;
}

.single-video-title {
  width: 70%;
  font-size: calc(2px + 1.2vmin);
  padding-bottom: 20px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
}

.custom-video-player {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 20px;
  margin-top: 10px;
  border-radius: 15px;
  max-width: 70%;
  margin: auto;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.single-video {
  width: 100%;
  max-height: 1100px;
  display: block;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
}

.play-pause,
.mute-control,
.fullscreen-control {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: opacity 0.3s;
}

.play-pause:hover,
.mute-control:hover,
.fullscreen-control:hover {
  opacity: 0.7;
}

/* Основной прогресс-бар с низкой прозрачностью */
.progress-bar {
  position: relative;
  flex-grow: 1;
  margin: 0 0 6px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
  cursor: pointer;
  -webkit-appearance: none;
}

/* Второй прогресс-бар, который показывает видимый прогресс */
.visible-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  width: 0;
  transition: width 0.1s linear;
  border-radius: 2px;
}

.controls button:focus,
.controls input:focus {
  outline: none;
}

.madonna-video {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {

  .container {
    cursor: n-resize;
    position: relative;
    height: 100svh;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: filter 0.3s ease;
    z-index: 3;
  }

  /* Ваши стили для мобильных устройств */
  .name {
    text-shadow: #000000 1px 0 10px;
    font-size: calc(10px + 3vmin);
    margin: 10px;
    transform: scale(1, 0.85);
    font-family: 'Avenir Next Cyr Bold';
    opacity: 0;
    animation: fadeIn 2s ease-out forwards;
  }

  /* Стили для контакта */
  .contact {
    letter-spacing: 2px;
    cursor: pointer;
    font-family: var(--font-family);
    color: var(--contact-color);
    font-size: calc(1px + 2vmin);
    position: fixed;
    top: 30px;
    left: 30px;
    z-index: 4;
    padding-bottom: 1px;
    display: inline-block;
    transform: scale(1, 0.9);
    transition: color 1s ease, color 1s ease;
    text-shadow: #000000 1px 0 10px;
  }

  .role {
    text-shadow: #000000 1px 0 10px;
    font-size: calc(1px + 2vmin);
    margin: 0;
    opacity: 0;
    animation: fadeIn 4s ease-out forwards;
  }

  .side-text {
    display: none;
  }

  .scroll-btn {
    letter-spacing: 2px;
    font-family: var(--font-family);
    font-size: calc(1px + 2vmin);
    position: absolute;
    bottom: 25px;
    color: var(--text-color);
    opacity: 0;
    animation: fadeInOutUp 1.2s ease-in-out infinite;
    transform: scale(1, 0.85);
    display: none;
  }

  .video-page-container {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    letter-spacing: 2px;
    font-family: var(--font-family);
    color: var(--text-color);
    transition: color 1s ease, color 1s ease;
    font-size: calc(1px + 2vmin);
    line-height: 25px;
    padding-top: 10px;
    text-transform: uppercase;
    width: 100%;

    z-index: 2;
  }


  .background-video {
    display: none;
  }

  .custom-video-player {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 20px;
    margin-top: 10px;
    border-radius: 15px;
    max-width: 99%;
    margin: auto;
  }

  .video-details {
    width: 95%;
    text-align: start;
    font-size: calc(1px + 2.5vmin);
    padding: 0px;
  }

  .single-video-title {
    font-size: calc(2px + 3vmin);
    padding-bottom: 20px;
  }

  .video-mobile {
    display: block;
    width: 100%;
  }

  .modal-content {
    text-transform: uppercase;
    font-family: var(--font-family);
    letter-spacing: 3px;
    color: var(--text-color);
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    line-height: 22px;
    font-size: calc(2px + 2vmin);
    transform: scale(1, 0.9);
  }

  video {
    background-image: none !important;
    width: 100%;

  }



  .contact {
    letter-spacing: 2px;
    cursor: pointer;
    font-family: var(--font-family);
    color: var(--contact-color);
    font-size: calc(3px + 2vmin);
    position: fixed;
    width: 100%;
    text-align: center;

    top: auto;
    height: 30px;
    left: 0;
    bottom: 0 !important;
    z-index: 4;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: -5px;
    display: inline-block;
    transform: scale(1, 0.9);
    transition: color 1s ease, color 1s ease;
    text-shadow: #000000 1px 0 10px;
    background: linear-gradient(to bottom,
        rgba(0, 0, 0, 0) 0%,
        /* Прозрачный вверху */
        rgba(0, 0, 0, 0.9) 100%
        /* Непрозрачный внизу */
      );

    backdrop-filter: blur(15px);
    /* Дополнительный размытие фона */

  }

  .contact::after {
    display: none;
  }

  .video-normal {
    display: none;
  }
}